import { useState, useEffect, useRef, useCallback, } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Box, Stack, IconButton, Typography } from '@mui/material'
import Moment from 'react-moment'
import ReactPlayer from 'react-player/file'
import { PlayArrow, Pause, FastForward, FastRewind, ClosedCaption as ClosedCaptionIcon, SignLanguage } from '@mui/icons-material'
import { checkValidCaptionObjetct } from '../../services/captions'

// TRIO Components
import Loading from '../components/Loading'
import ClosedCaption from '../components/ClosedCaptions'

// Adapters
import { getEventRoomInfo, getStaticEventCaptions } from '../../adapters/Events.adapter'

// Interfaces
import { IEventRooms } from './Interfaces.events'
import { CaptionObject, LocalCaptions } from '../../types/LocalCaptions'

// Styles
import { mainStyle } from '../../styles/Index.Styles'
import Header from '../components/Header'

export default function StaticEventPlayer() {
    const { eventId, roomId } = useParams()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const [roomInfo, setRoomInfo] = useState<IEventRooms | undefined>(undefined)
    const [sourceLink, setSourceLink] = useState<string | undefined>(undefined)
    const [play, setPlay] = useState(false)

    const [availableAccessibilities, setAvailableAccessibilities] = useState<{cc: boolean, lb: boolean, ad: boolean}>({cc: false, lb: false, ad: false})

    const [mutePlayer, setMutePlayer] = useState(false)
    const [showCC, setShowCC] = useState(true)
    const [showLibras, setShowLibras] = useState(true)
    const [showControls, setShowControls] = useState(true)

    const [mediaCurrentTime, setMediaCurrentTime] = useState(15)
    const [subsJsonLocal, setSubsJsonLocal] = useState<LocalCaptions | null>(
        null
    )
    const [captionIndex, setCaptionIndex] = useState<number | null>(null)
    const [captionLanguage, setCaptionLanguage] = useState<string>('en-US')

    const playerRef = useRef<ReactPlayer>(null)
    const timeoutRef = useRef<number | undefined>(undefined)

    // eslint-disable-next-line
    const readJsonFile = useCallback(async function (captionUrl: string) {
        if (!roomId) return
        const localFolderPath = 'transcriptions'
        const localSubsFileName = `${roomId}.json`
        const subsJsonLocalPath = `${localFolderPath}/${localSubsFileName}`
        const subsJson = window.localStorage.getItem(subsJsonLocalPath)
        if (subsJson) {
            try {
                const parsedSubsJson = JSON.parse(subsJson)
                const isValidCaption = checkValidCaptionObjetct(parsedSubsJson)

                if (!isValidCaption) {
                    throw new Error(
                        'Invalid caption object, will set default empty object'
                    )
                }
                setSubsJsonLocal(parsedSubsJson)
                const language = Object.keys(parsedSubsJson.captions)[0]
                setCaptionLanguage(language)
            } catch (error) {
                console.error(error)
                setSubsJsonLocal({
                    captions: { 'en-US': [] },
                })
            }
        } else {
            const subsRes = await getStaticEventCaptions(captionUrl)
            
            const subsString = JSON.stringify(subsRes.data)
            
            setSubsJsonLocal(subsRes.data)
            window.localStorage.setItem(subsJsonLocalPath, subsString)
        }
    },[roomId])

    useEffect(() => {
        if (eventId !== undefined && roomId !== undefined) {
            getEventRoomInfo(eventId, roomId)
                .then((res) => {
                    const data = res.data
                    const dataInfo: IEventRooms = {
                        name: data.room_name,
                        description: data.description,
                        event: data.event.event_name,
                        eventUuid: data.event.id,
                        roomUuid: data.id,
                        startDate: new Date(data.start_date),
                        stopDate: new Date(data.stop_date),
                        active: data.available,
                        ad_stream_link: data.ad_stream_link,
                        cc_stream_link: data.cc_stream_link,
                        cc_us_stream_link: data.cc_us_stream_link,
                        lb_stream_link: data.lb_stream_link,
                        static_stream_link: data.static_stream_link,
                        static_accessibility_type: data.static_accessibility_type
                    }
                    setSourceLink(data.static_stream_link+'out.m3u8')
                    setRoomInfo(dataInfo)
                    if(data.static_accessibility_type) {
                        const resTypes = data.static_accessibility_type
                        const accesses = {cc: resTypes.cc, lb: resTypes.lb, ad: resTypes.ad}

                        setAvailableAccessibilities(accesses)
                    }
                    //readJsonFile(data.static_stream_link+'subs.json')
                })
                .catch((err) => {
                    console.error(err)
                })
        } else {
            navigate('/')
        }
    }, [eventId, roomId, searchParams, navigate])

    const onUserActivity = useCallback(() => {
        if(timeoutRef.current || showControls) {
            window.clearTimeout(timeoutRef.current)
            const timeout = window.setTimeout(() => {
                setShowControls(false)
            }, 10000)
            timeoutRef.current = timeout
        }
        setShowControls(true)
        return () => {
            window.clearTimeout(timeoutRef.current)
        }
    }, [timeoutRef, showControls])

    useEffect(() => {
        let timeout: number | undefined = undefined

        if(timeoutRef.current || showControls) {
            window.clearTimeout(timeoutRef.current)
            const timeout = window.setTimeout(() => {
                setShowControls(false)
            }, 10000)
            timeoutRef.current = timeout
        }

        window.addEventListener('touchstart', onUserActivity)
        window.addEventListener('mousemove', onUserActivity)
        window.addEventListener('keydown', onUserActivity)

        return () => {
            window.clearTimeout(timeout)
            window.removeEventListener('touchstart', onUserActivity)
            window.removeEventListener('mousemove', onUserActivity)
            window.removeEventListener('keydown', onUserActivity)
        }
    },[showControls, onUserActivity])


    useEffect(() => {
        const getCaptionIndex = () => {
            const captions = subsJsonLocal?.captions
            if (!captions) return null
            const captionList = captions[captionLanguage]
            const newIndex = captionBinarySearch(captionList, mediaCurrentTime)
            setCaptionIndex(newIndex)
        }

        if (!!subsJsonLocal) {
            getCaptionIndex()
        }
    }, [mediaCurrentTime, subsJsonLocal, captionLanguage])

    const togglePlay = () => {
        setPlay((isPlaying) => !isPlaying)
    }

    const seekPlayer = (direction: 'forward' | 'rewind') => {
        if(playerRef.current) {
            const currentTime = playerRef.current.getCurrentTime()
            if(direction === 'forward') {
                playerRef.current.seekTo(currentTime+5)
            } else {
                playerRef.current.seekTo(currentTime-5)
            }
        }
    }

    const captionBinarySearch = (
        captionList: CaptionObject[],
        currentTime: number
    ) => {
        let left = 0
        let right = captionList.length - 1
        while (left <= right) {
            const mid = Math.floor((left + right) / 2)
            if (
                captionList[mid].start / 1000000 <= currentTime &&
                captionList[mid].end / 1000000 > currentTime
            ) {
                return mid
            } else if (captionList[mid].start / 1000000 < currentTime) {
                left = mid + 1
            } else {
                right = mid - 1
            }
        }
        return -1
    } 

    if (sourceLink === undefined)
        return (
            <Loading />
        )

    return (
        <Box sx={mainStyle.root}>
            <Header />
            <Box sx={mainStyle.page}>
                <Box sx={mainStyle.content}>
                    <Box sx={{overflow: 'auto', height: '100%', padding: .2, borderRadius: 6}}>
                        <Box sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                            <Box sx={{padding: 1}}>
                                <Box sx={[mainStyle.flexRow, mainStyle.justifyConent]}>
                                    <Typography
                                        component='span'
                                        sx={{
                                            color: '#000',
                                            fontWeight: 'bold',
                                            fontSize: 26,
                                        }}
                                    >
                                        {roomInfo?.event}
                                    </Typography>
                                </Box>
                                <Box sx={[mainStyle.flexRow, {padding: 1}]}>
                                    <Box>
                                        <Typography
                                            component='span'
                                            sx={{
                                                color: '#000',
                                                fontWeight: 'bold',
                                                fontSize: 16
                                            }}
                                        >
                                            Sala:
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            sx={{paddingLeft: .5}}
                                        >
                                            {roomInfo?.name}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    sx={
                                        [
                                            mainStyle.flexRow,
                                            { 
                                                padding: .5, paddingLeft: 1
                                            }
                                        ]
                                    }
                                >
                                    <Box>
                                        <Typography
                                            component='span'
                                            sx={{
                                                color: '#000',
                                                fontWeight: 'bold',
                                                fontSize: 16
                                            }}
                                        >
                                            Transmissão:
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            sx={{paddingLeft: .5}}
                                        >
                                            <Moment date={roomInfo?.startDate} format='DD/MM/YYYY' /> - <Moment date={roomInfo?.startDate} format='HH:mm' /> às <Moment date={roomInfo?.stopDate} format='HH:mm' />
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{flexGrow: 1, position: 'relative', width: '100%',}}>
                                <Stack height={'100%'} position={'absolute'} justifyContent={'space-between'} direction={'column'} zIndex={99} display={showControls? 'flex':'none'}
                                >
                                    <Stack gap={2}>
                                        {availableAccessibilities.ad &&
                                            <IconButton
                                                aria-description={mutePlayer? 'Ativar audiodescrição' : 'Desativar audiodescrição'}
                                                sx={!mutePlayer? mainStyle.iconButton: mainStyle.disabledIconButton}
                                                onClick={() => setMutePlayer((muted) => !muted)}
                                            >
                                                <Typography sx={{fontSize: '26px', fontWeight: 'bold'}}>AD</Typography>
                                            </IconButton>
                                        }
                                        {availableAccessibilities.lb &&
                                            <IconButton
                                                aria-description={showLibras? 'Desativar LIBRAS' : 'Ativar LIBRAS'}
                                                sx={showLibras? mainStyle.iconButton: mainStyle.disabledIconButton}
                                                onClick={() => setShowLibras((show) => !show)}
                                            >
                                                <SignLanguage fontSize='large'/>
                                            </IconButton> }
                                        {availableAccessibilities.cc && 
                                            <IconButton
                                                aria-description={showCC? 'Desativar Closed Captions' : 'Ativar Closed Captions'}
                                                sx={showCC? mainStyle.iconButton: mainStyle.disabledIconButton}
                                                onClick={() => setShowCC((show) => !show)}
                                            >
                                                <ClosedCaptionIcon fontSize='large'/>
                                            </IconButton> 
                                        }
                                    </Stack>
                                    <Stack gap={2}>
                                        <IconButton
                                            sx={mainStyle.iconButton}
                                            onClick={() => seekPlayer('forward')}
                                        >
                                            <FastForward/>
                                        </IconButton>
                                        <IconButton
                                            sx={mainStyle.iconButton}
                                            onClick={() => seekPlayer('rewind')}
                                        >
                                            <FastRewind/>
                                        </IconButton>
                                        <IconButton
                                            sx={mainStyle.iconButton}
                                            onClick={togglePlay}
                                        >
                                            {play ? <Pause/> : <PlayArrow/>}
                                        </IconButton>
                                    </Stack>
                                </Stack>
                                <Box display={'flex'} flexDirection={'column'} height={'100%'} width={'100%'} justifyContent={'center'} alignItems={'center'}>
                                    <ReactPlayer
                                        ref={playerRef}
                                        style={{
                                            //display: 'none',
                                            width: '99%',
                                            height: 340,
                                            borderRadius: 10,
                                            padding: 0,
                                            paddingTop: 1,
                                            overflow: 'hidden'
                                        }}
                                        title="Player de acessibilidade"
                                        url={sourceLink}
                                        allow="autoplay;"
                                        playing={play}
                                        muted={mutePlayer}
                                        config={{forceHLS: true, forceSafariHLS: true}}
                                        onProgress={(event) => {
                                            setMediaCurrentTime(event.playedSeconds)
                                        }}
                                    />
                                    <Box
                                        style={{
                                            position: showLibras
                                                ? 'relative'
                                                : 'absolute',
                                            backgroundColor: 'black',
                                            zIndex: 99,
                                        }}
                                    >
                                        <ClosedCaption
                                            ccOn={showCC}
                                            captionIndex={captionIndex}
                                            captions={subsJsonLocal?.captions}
                                            captionLanguage={captionLanguage}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>

    )
}
