import { useCallback, useEffect, useRef, useState } from "react"
import ReactPlayer, { type ReactPlayerProps } from "react-player"
import { Button } from "./Button"
import { darkGreen, lighterGreen, lightGreen, lightText } from "../../styles/Index.Styles"

import FastForwardIcon from "@mui/icons-material/FastForward"
import FastRewindIcon from "@mui/icons-material/FastRewind"
import PauseIcon from "@mui/icons-material/Pause"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import VolumeUpIcon from "@mui/icons-material/VolumeUp"
import FullscreenIcon from "@mui/icons-material/Fullscreen"
import VolumeOffIcon from "@mui/icons-material/VolumeOff"
import AutoSyncButton from "./AutoSyncButton"
import type { IMedia } from "../media/Interfaces.media"
import { Box, Typography, Slider } from "@mui/material"

type VideoPlayerProps = ReactPlayerProps & {
    mediaData: IMedia | Record<PropertyKey, never>
}

export default function VideoPlayer({ mediaData, url }: VideoPlayerProps) {
    const videoRef = useRef<ReactPlayer | null>(null)
    const playerContainerRef = useRef<HTMLDivElement | null>(null)

    const [isPlayingMedia, setIsPlayingMedia] = useState<boolean>(false)
    const [isFullscreen, setIsFullscreen] = useState<boolean>(false)
    const [volume, setVolume] = useState<number>(0.5)

    function skipForward() {
        if (videoRef.current) {
            const currentTime = videoRef.current.getCurrentTime()
            videoRef.current.seekTo(currentTime + 2, "seconds")
        }
    }

    function skipBackward() {
        if (videoRef.current) {
            const currentTime = videoRef.current.getCurrentTime()
            videoRef.current.seekTo(currentTime - 2, "seconds")
        }
    }

    const toggleFullScreen = useCallback(() => {
        const container = playerContainerRef.current

        if (!container) {
            return
        }

        if (!isFullscreen) {
            container.style.position = "fixed"
            container.style.top = "0"
            container.style.left = "0"
            container.style.width = "100dvw"
            container.style.height = "100dvh"
            container.style.backgroundColor = "black"
            container.style.overflow = "hidden"

            setIsFullscreen(true)
        } else {
            container.style.position = "relative"
            container.style.top = ""
            container.style.left = ""
            container.style.width = "100%"
            container.style.height = "100%"
            container.style.backgroundColor = ""
            container.style.overflow = ""

            setIsFullscreen(false)
        }
    }, [isFullscreen])

    const handlePlay = useCallback(() => {
        setIsPlayingMedia(true)
    }, [])

    const handlePause = useCallback(() => {
        setIsPlayingMedia(false)
    }, [])

    const togglePlayPause = useCallback(() => {
        if (videoRef.current) {
            const isPlaying =
                videoRef.current.getCurrentTime() > 0 &&
                !videoRef.current.getInternalPlayer().paused
            isPlaying
                ? videoRef.current.getInternalPlayer().pause()
                : videoRef.current.getInternalPlayer().play()
            setIsPlayingMedia(!isPlaying)
        }
    }, [])

    const handleVolumeChange = useCallback((event: Event, newValue: number | number[]) => {
        const value = Array.isArray(newValue) ? newValue[0] : newValue
        setVolume(value / 100)
    }, [])

    function seekTo(seconds: number) {
        if (videoRef.current) {
            videoRef.current.seekTo(seconds)
        }
    }

    useEffect(() => {
        toggleFullScreen()
        // eslint-disable-next-line
    }, [])

    return (
        <Box
            style={{ position: "relative", width: "100%", height: "100%" }}
            ref={playerContainerRef}
        >
            <ReactPlayer
                ref={videoRef}
                url={url}
                playing={isPlayingMedia}
                volume={volume}
                width="100%"
                height="100%"
                onPlay={handlePlay}
                onPause={handlePause}
                controls={false}
                config={{
                    file: {
                        attributes: {
                            playsInline: true,
                            controlsList: 'nodownload noplaybackrate nocaptions nofullscreen',
                            disablePictureInPicture: true,
                        }
                    }
                }}
            />

            <Box
                style={{
                    position: "absolute",
                    top: "10%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    width: '100%',
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 1,
                }}
            >
                <Typography
                    style={{
                        color: lighterGreen,
                        fontSize: '18px'
                    }}
                >
                    {mediaData.title}
                </Typography>
            </Box>

            <Box
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "15%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: 'column',
                    gap: "1rem",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 1,
                }}
            >
                <Button onClick={skipBackward}>
                    <Button.Icon icon={FastRewindIcon} iconColor={lightGreen} />
                </Button>
            

            {isPlayingMedia ? (
                <Box style={{ backgroundColor: darkGreen, borderRadius: '50%' }}>
                    <Button onClick={togglePlayPause}>
                        <Button.Icon icon={PauseIcon} iconColor={lightText} />
                    </Button>
                </Box>
            ) : (
                <Box style={{ backgroundColor: darkGreen, borderRadius: '50%' }}>
                    <Button onClick={togglePlayPause}>
                        <Button.Icon icon={PlayArrowIcon} iconColor={lightText} />
                    </Button>
                </Box>
            )}
            </Box>

            <Box
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "85%",
                    transform: "translate(-50%, -50%)",
                    display: "flex",
                    flexDirection: 'column',
                    gap: "1rem",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 1,
                }}
            >
                <Button onClick={skipForward}>
                    <Button.Icon icon={FastForwardIcon} iconColor={lightGreen} />
                </Button>

                <AutoSyncButton
                    mediaData={mediaData}
                    seekTo={seekTo}
                    onPause={handlePause}
                    onPlay={handlePlay}
                />
            </Box>

            <Box
                style={{
                    position: "absolute",
                    bottom: "0",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    zIndex: 1,
                }}
            >
                <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    {volume === 0 && (
                        <Button onClick={() => setVolume(0.5)}>
                            <Button.Icon
                                icon={VolumeOffIcon}
                                iconColor={lightText}
                                size="24px"
                            />
                        </Button>
                    )}
                    {volume !== 0 && (
                        <Button onClick={() => setVolume(0)}>
                            <Button.Icon
                                icon={VolumeUpIcon}
                                iconColor={lightText}
                                size="24px"
                            />
                        </Button>
                    )}
                    <Slider
                        value={volume * 100}
                        onChange={(e, value) => handleVolumeChange(e, value)}
                        min={0}
                        max={100}
                        style={{ width: "70px", color: lightGreen, height: '2px' }}
                    />
                </Box>

                <Button onClick={toggleFullScreen}>
                    <Button.Icon
                        icon={FullscreenIcon}
                        iconColor={lightText}
                        size="24px"
                    />
                </Button>
            </Box>
        </Box>
    )
}