import { z } from "zod";

const envSchema = z.object({
  REACT_APP_API_URL: z.string().url(),
  REACT_APP_API_KEY: z.string().min(1),
  REACT_APP_API_AUTO_SYNC: z.string().url(),
})

const _env = envSchema.parse(process.env)

export const env = _env