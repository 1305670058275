import { ReactNode } from "react"
import { SvgIconComponent } from "@mui/icons-material"

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    children: ReactNode
    onClick?: () => void
}

function Button({ children, onClick, disabled, ...rest }: ButtonProps) {
    return (
        <button
            aria-label="Avançar mídia"
            onClick={onClick}
            style={{
                all: "unset",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: disabled ? "not-allowed" : "pointer",
                transition: "background-color 0.3s ease",
                opacity: disabled ? 0.5 : 1,
            }}
            {...rest}
        >
            {children}
        </button>
    )
}

type IconProps = {
    icon: SvgIconComponent
    iconColor: string
    size?: string
}

function Icon({ icon: Icon, iconColor, size = '40px' }: IconProps) {
    return <Icon style={{ color: iconColor, fontSize: size, padding: '8px' }} />
}

Button.Icon = Icon

export { Button }