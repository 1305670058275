import { Outlet } from 'react-router-dom'
import InstallPromptModal from './components/InstallPromptModal';
import { ToastContainer } from 'react-toastify'

function MainWindow() {
    return (
        <>
            <InstallPromptModal />
            <ToastContainer
                position='bottom-center'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                limit={3}
            />
            <Outlet />
        </>
    )
}

export default MainWindow
