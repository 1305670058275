import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { 
    Box,
    Typography,
    Stack
} from '@mui/material'

// Adapters
import { getEventRoomsAvailable } from '../../adapters/Events.adapter'

// Interfaces
import { IEventRoom, IEventSingular } from './Interfaces.events'

// TRIO Components
import EventRoomCard from '../components/EventRoomCard'
import Loading from '../components/Loading'
import { mainStyle } from '../../styles/Index.Styles'
import Header from '../components/Header'

import { SELECTED_DAY } from '../../constants/day'

export default function RoomsEvents() {
    const { eventId } = useParams()

    const [eventDurationDays, setEventDurationDays] = useState(0)
    const [eventInfo, setEventInfo] = useState<IEventSingular | null>(null)
    const [isLoadingEvent, setIsLoadingEvent] = useState<boolean>(false)
    const [selectedDayRooms, setSelectedDayRooms] = useState<IEventRoom[] | null>(null)

    const daySelected = localStorage.getItem(SELECTED_DAY)
    const navigate = useNavigate()

    const intToWeekDay = (weekDay: number) => {
        const weekDays = ['DOMINGO', 'SEGUNDA-FEIRA', 'TERÇA-FEIRA', 'QUARTA-FEIRA', 'QUINTA-FEIRA', 'SEXTA-FEIRA', 'SÁBADO']
        return weekDays[weekDay] || ''
    }

    const handleNavigateToRoom = (event: IEventRoom) => {
        if(event.static_stream_link !== '') {
            navigate(`/event/${eventInfo?.id}/${event.id}/static_player`)
        } else {
            navigate(`/event/${eventInfo?.id}/${event.id}`)
        }
    }

    const renderEventRoomCards = () => {
        if (eventInfo?.rooms.length === 0 || selectedDayRooms?.length === 0) {
            return (
                <Typography
                    sx={{ padding: 4, display: 'flex', justifyContent: 'center' }}
                >
                    Nenhuma Sala encontrada para este evento!
                </Typography>
            )
        }
        if (!daySelected && eventDurationDays > 1) {
            const dayCards = []
            if (eventInfo) {
                const start = new Date(eventInfo.start_date)
                for (let day = 0; day < eventDurationDays; day++) {
                    const currentDay = new Date(start)
                    currentDay.setDate(currentDay.getDate() + day * 1)
                    dayCards.push(
                        <Box
                            sx={[mainStyle.eventDayCard]}
                            key={`day-${day}-card`}
                            onClick={() => {
                                localStorage.setItem(SELECTED_DAY, String(currentDay))
                                setIsLoadingEvent(true)
                            }}
                        >
                            <Typography
                                sx={[mainStyle.eventDayCardDate]}
                            >
                                {`${currentDay.getDate()}/${currentDay.getMonth() + 1}`}
                            </Typography>
                            <Typography
                                sx={[mainStyle.eventDayCardWeekDay]}
                            >
                                {intToWeekDay(currentDay.getDay())}
                            </Typography>
                        </Box>
                    )
                }
            }

            return (
                <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <Box sx={[mainStyle.eventDayCardsView]}>{dayCards}</Box>
                </Box>
            )
        } else {
            return (
                <Stack gap={1}>
                    {eventInfo && selectedDayRooms?.map(e => (
                        <Box 
                            key={e.id}
                            onClick={() => {handleNavigateToRoom(e)}}
                        >
                            <EventRoomCard
                                eventName={eventInfo.event_name}
                                roomName={e.room_name}
                                active={e.available}
                                eventUuid={eventInfo.id}
                                roomUuid={e.id}
                                startDate={new Date(e.start_date)}
                                stopDate={new Date(e.stop_date)}
                                hasAd={e.ad_stream_link !== ''}
                                hasCc={e.cc_stream_link !== '' || e.cc_us_stream_link !== ''}
                                hasLb={e.lb_stream_link !== ''}
                            />
                        </Box>
                    ))}
                </Stack>
            )
        }
    }

    useEffect(() => {
        if (daySelected && eventInfo) {
            const selectedDay = new Date(daySelected)
            const filteredRooms = eventInfo.rooms.filter((room) => {
                const roomStart = new Date(room.start_date)
                const roomEnd = new Date(room.stop_date)

                if (roomStart.getDate() === selectedDay.getDate() ||
                    roomEnd.getDate() === selectedDay.getDate() ||
                    (roomStart <= selectedDay && roomEnd >= selectedDay)
                ) {
                    return true
                }

                return false
            })

            setSelectedDayRooms(filteredRooms)
        }

        setIsLoadingEvent(false)
    }, [eventInfo, daySelected])


    useEffect(() => {
        if (eventId) {
            const getEventInfo = async () => {
                try {
                    setIsLoadingEvent(true)
                    const { data } = await getEventRoomsAvailable(eventId)
                    setEventInfo(data)
                    const start = new Date(data.start_date)
                    const end = new Date(data.stop_date)
                    const difference = end.getTime() - start.getTime()
                    const differenceInDays =   Math.round
                        (difference / (1000 * 3600 * 24))
                    setEventDurationDays(differenceInDays + 1)                    
                    if(differenceInDays === 0) {
                        setSelectedDayRooms(data.rooms)
                    }
                } catch (error) {
                    console.error(error)
                } finally {
                    setIsLoadingEvent(false)
                }
            }
            getEventInfo()
        }
    }, [eventId])

    return (
        <Box sx={mainStyle.root}>
            <Header/>
            <Box sx={mainStyle.page}>
                <Box sx={mainStyle.content}>
                        <>
                            {isLoadingEvent && <Loading />}
                            {!isLoadingEvent && eventInfo && renderEventRoomCards()}
                        </>
                </Box>
            </Box>
        </Box>
    )
}
