import {
    AxiosResponse,
} from 'axios'

// Settings
import axiosHelper from './Axios.Helper'
import { IMedia } from '../screens/media/Interfaces.media'
import { env } from '../env'

export async function getMediaById(mediaId: string): Promise<AxiosResponse<IMedia>> {
    return await axiosHelper({
        method: 'GET',
        url: `${env.REACT_APP_API_URL}/content/${mediaId}`,
    })
}

const autoSyncBriefSoundPath = (index = 0): string => {
    return `sound_${index}.webm`
}

type AutoSyncResponse = number | { detail: string }

export async function autoSyncMovie(
    fp: string,
    audioBlob: Blob,
): Promise<AxiosResponse<AutoSyncResponse>> {
    const filePath = autoSyncBriefSoundPath()

    const formData = new FormData()
    formData.append("file", audioBlob, filePath)

    const response = await axiosHelper({
        method: 'POST',
        headers: {
            Accept: "application/json",
        },
        data: formData,
        url: `${env.REACT_APP_API_AUTO_SYNC}/sync/get_video_time/${fp}`
    })

    return response
}