import { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import { Box } from "@mui/material";

// Adapters
import { getMediaById } from '../../adapters/Media.adapter';

// TRIO Components
import Header from "../components/Header";
import { mainStyle } from "../../styles/Index.Styles";
import { IMedia } from './Interfaces.media';
import VideoPlayer from '../components/VideoPlayer';
import { errorAlert, successAlert } from '../../services/toasts';

export default function MediaPlayer() {
    const { mediaId } = useParams()

    const [mediaData, setMediaData] = useState<IMedia | Record<PropertyKey, never>>({})
    const [mediaUrl, setMediaUrl] = useState<string>('')
    const [error, setError] = useState<boolean>(false)
    const [, setErrorMessage] = useState<string>('')

    useEffect(() => {
        const getMediaData = async () => {
            try {
                if (mediaId) {
                    const response = await getMediaById(mediaId)
                    setMediaData(response.data)
                    const url = `${response.data.content_path}${response.data.stream_path}`
                    if (url) {
                        setMediaUrl(url)
                        successAlert(`Mídia encontrada: ${response.data.title}`)
                    } else {
                        throw new Error('URL inválida ou vazia.')
                    }
                }
            } catch (e) {
                console.error(e)
                setError(true)
                setErrorMessage(String(e))
                errorAlert('Mídia não encontrada.')
            }
        }
        getMediaData()
    }, [mediaId])

    useEffect(() => {
        return () => {
            setMediaData({})
            setError(false)
            setErrorMessage('')
        }
    }, [])

    return (
        <Box sx={mainStyle.root}>
        <Header />
            <Box sx={mainStyle.page}>
                <Box sx={mainStyle.content}>
                    <Box
                        sx={{
                            overflow: 'auto',
                            height: '100%',
                            padding: 0.2,
                            borderRadius: 6,
                            gap: '1rem',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {!error && mediaUrl && (
                                <Box
                                    sx={{
                                        margin: '0.5rem',
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <VideoPlayer
                                        mediaData={mediaData}
                                        url={mediaUrl}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}