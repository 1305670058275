import { toast, type ToastPosition } from 'react-toastify'

export function successAlert(message: string, position: ToastPosition = 'bottom-center') {
    if(typeof(message) === "string" && message !== "")
        toast.success(message, {
            position,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        })
}

export function errorAlert(message: string, position: ToastPosition = 'bottom-center') {
    if(typeof(message) === "string" && message !== "")
        toast.error(message, {
            position,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        })
}

export function warnAlert(message: string, position: ToastPosition = 'bottom-center') {
    if(typeof(message) === "string" && message !== "")
        toast.warn(message, {
            position,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        })
}

export function infoAlert(message: string, position: ToastPosition = 'bottom-center') {
    if(typeof(message) === "string" && message !== "")
        toast.info(message, {
            position,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        })
}
