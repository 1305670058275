import { useState } from "react"
import { autoSyncMovie } from "../../adapters/Media.adapter"
import { darkGreen, lightText } from "../../styles/Index.Styles"
import type { IMedia } from "../media/Interfaces.media"
import { Button } from "./Button"
import RefreshIcon from '@mui/icons-material/Refresh'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import { errorAlert, infoAlert, successAlert, warnAlert } from "../../services/toasts"
import { convertSecondsToHours } from "../../utils/convertSecondsToHours"

type AutoSyncButtonProps = {
    mediaData: IMedia | Record<PropertyKey, never>
    seekTo: (seconds: number) => void
    onPause: () => void
    onPlay: () => void
}

export default function AutoSyncButton({
    mediaData,
    seekTo,
    onPause,
    onPlay,
}: AutoSyncButtonProps) {
    const [isInSynchronization, setIsInSynchronization] = useState<boolean>(false)

    async function startRecord(
        initialValue = 10,
        increment = 0,
        limit = 10,
        attempt = 1,
        maxAttempts = 3
    ): Promise<void> {
        let mediaRecorder: MediaRecorder | null = null
        let stream: MediaStream | null = null
        const chunks: BlobPart[] = []
        infoAlert("Aguarde um momento, o tempo de filme será sincronizado em alguns segundos.")
        try {
            setIsInSynchronization(true)
            try {
                stream = await navigator.mediaDevices.getUserMedia({ audio: true })
            } catch (err) {
                console.error("Permissões de microfone negadas ou indisponíveis:", err)
                warnAlert("Permissões de microfone necessárias para sincronização!")
                return
            }

            mediaRecorder = new MediaRecorder(stream)
            mediaRecorder.ondataavailable = (event) => chunks.push(event.data)
            mediaRecorder.start()
            onPause()

            await new Promise((resolve) => setTimeout(resolve, initialValue * 1000))
            mediaRecorder.stop()

            mediaRecorder.onstop = async () => {
            const audioBlob = new Blob(chunks, { type: "audio/webm" })

                try {
                    if (!mediaData.id_fp) {
                        throw new Error("Ocorreu um erro ao realizar a sincronização!")
                    }

                    const syncRequestStart = new Date().getTime()
                    const response = await autoSyncMovie(mediaData.id_fp, audioBlob)
                    const syncRequestSpentTime = Math.ceil((new Date().getTime() - syncRequestStart)/1000)

                    if (response.status === 200) {
                        if (typeof response.data === "number") {
                            const position = (response.data + initialValue + syncRequestSpentTime)
                            const time = convertSecondsToHours(position)
                            successAlert(`Tempo encontrado: ${time}`)
                            seekTo(Math.ceil(position))
                            if (time) {
                                onPlay()
                                return
                            }
                        }
                    }

                    if (attempt <= maxAttempts && initialValue + increment <= limit) {
                        await startRecord(
                            initialValue + increment,
                            increment,
                            limit,
                            attempt + 1,
                            maxAttempts
                        )
                    }
                } catch (syncError) {
                    console.error("Erro na sincronização:", syncError)

                    if (attempt < maxAttempts) {
                        await startRecord(
                            initialValue,
                            increment,
                            limit,
                            attempt + 1,
                            maxAttempts
                        )
                    } else {
                        errorAlert("Todas as tentativas de sincronização falharam. Tente novamente!")
                    }
                }
            }
        } catch (error) {
            console.error("Erro durante a gravação:", error)

            if (attempt < maxAttempts) {
                await startRecord(initialValue, increment, limit, attempt + 1, maxAttempts)
            } else {
                errorAlert("Todas as tentativas de gravação falharam.")
            }
        } finally {
            if (mediaRecorder && mediaRecorder.state !== "inactive") {
                mediaRecorder.stop()
            }
            if (stream) {
                stream.getTracks().forEach((track) => track.stop())
            }
            setIsInSynchronization(false)
        }
    }

    return (
        <div style={{ backgroundColor: darkGreen, borderRadius: '50%' }}>
            {isInSynchronization ? (
                <Button
                    disabled
                >
                    <Button.Icon icon={HourglassTopIcon} iconColor={lightText} />
                </Button>
            ) : (
                <Button
                    onClick={() => startRecord()}
                >
                    <Button.Icon icon={RefreshIcon} iconColor={lightText} />
                </Button>
            )}
        </div>
    )
}
