import axios from 'axios'
import { env } from '../env'

const axiosHelp = axios.create()

axiosHelp.interceptors.request.use(
    (config) => {
        config.headers.Authorization = `Bearer ${env.REACT_APP_API_KEY}`
        config.headers["x-api-version"] = "1.0.0"
        return config;
    },
    (error) => Promise.reject(error)
)

axiosHelp.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error)
)

function axiosHelper(config: any) {
    return axiosHelp(config)
}

export default axiosHelper